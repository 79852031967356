
import { defineComponent } from 'vue';
import { IonPage, IonHeader, IonTitle, IonContent, IonMenuButton, IonToolbar, IonButtons } from '@ionic/vue';


export default defineComponent({
    name: 'Sidemenu Layout',
    props: {
        title: {
            type: String,
            required: true
        },
    },
    components: {
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
    },
    setup() {
        return {}
    },
})
