
import { defineComponent } from 'vue';
import SidemenuLayout from '../layouts/SidemenuLayout.vue';

export default defineComponent({
    name: 'Profile',
    components: {
        SidemenuLayout
    },
    setup() {
        return {}
    },
})
