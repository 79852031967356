<template>
    <ion-page>
        <ion-header :translucent="true" class="lg:hidden">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button color="primary"></ion-menu-button>
                </ion-buttons>
                <ion-title>{{title}}</ion-title>
            </ion-toolbar>
        </ion-header>
        
        <ion-content :fullscreen="true">
            
            <slot />
            
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonPage, IonHeader, IonTitle, IonContent, IonMenuButton, IonToolbar, IonButtons } from '@ionic/vue';


export default defineComponent({
    name: 'Sidemenu Layout',
    props: {
        title: {
            type: String,
            required: true
        },
    },
    components: {
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
    },
    setup() {
        return {}
    },
})
</script>